import Vue from 'vue'
import VueRouter from 'vue-router'
import Inicio from '../views/Inicio.vue'
import Sobre from "../views/Sobre.vue"
import Ayuda from "../views/Ayuda.vue"
import Aviso from "../views/Aviso.vue"
import Suger from "../views/Suger.vue"
// import loan from "../views/loan.vue"
// import complaints from "../views/complaints.vue"
// import fraude from "../views/fraude.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: "/Inicio",
  },
  {
    path: '/Inicio',
    name: 'Inicio',
    component: Inicio
  },
  {
    path: '/Sobre',
    name: 'Sobre',
    component: Sobre
  },
  {
    path: '/Ayuda',
    name: 'Ayuda',
    component: Ayuda
  },
  {
    path: '/Aviso',
    name: 'Aviso',
    component: Aviso
  },
  {
    path: '/Suger',
    name: 'Suger',
    component: Suger
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
