import axios from 'axios'

export default {
	data() {
		return {
			// apiHead: 'https://api-test-co.jinglewill.com/', //测试
			apiHead: 'https://api.multicartera.co/', //正式
			dataInfo: {},
		}
	},
	mounted() {
		axios
			.post(this.apiHead + 'gateway/ApiService/api/v8/h5/auth/appsflyer/appStatisticsNew', {
				source: 'Multicartera',
			})
			.then((res) => {
				if (res.data.code == 0) {
					this.dataInfo = res.data.data
				}
			})
	},
}
