<template>
  <div class="inicio">
    <div class="container">
      <head-nav :activeNav="'Inicio'"></head-nav>
      <div class="top">
        <div class="right">
          <p>
            Obtén tu préstamo de mayor importe, con menores<br />
            comisiones
          </p>
          <div class="right_img">
            <a
              href="https://play.google.com/store/apps/details?id=bank.loan.fast.money.multicartera"
              target="_blank"
            >
              <img src="@/assets/image/Inicio/playBtn.png" alt="" />
            </a>
            <img src="@/assets/image/Inicio/fl.png" alt="" class="fl" />
          </div>
        </div>
        <img src="@/assets/image/Inicio/one.png" alt="" />
      </div>
      <div class="middle">
        <h1>Producto Especial</h1>
        <div class="option">
          <div>
            <img src="@/assets/image/Inicio/option_one.png" alt="" />
            <p>Montó maximo</p>
          </div>
          <div>
            <img src="@/assets/image/Inicio/option_two.png" alt="" />
            <p>Menores comisiones</p>
          </div>
          <div>
            <img src="@/assets/image/Inicio/option_three.png" alt="" />
            <p>Mayor plazo</p>
          </div>
          <div>
            <img src="@/assets/image/Inicio/option_four.png" alt="" />
            <p>
              Recomendaciones<br />
              personalizadas
            </p>
          </div>
        </div>
      </div>
      <foot-page></foot-page>
    </div>
    <com-yuan></com-yuan>
  </div>
</template>

<script>
import footPage from "../components/footPage.vue";
import headNav from "../components/headNav.vue";
import comYuan from "../components/comYuan.vue";

export default {
  name: "Inicio",
  components: {
    headNav,
    footPage,
    comYuan,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.inicio {
  position: relative;
  background: url("../assets/image/star.png") repeat;
  background-size: 100%;
  .container {
    z-index: 9999;
    position: relative;
    .top {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      padding: 48px 0 40px;
      box-sizing: border-box;
      img {
        width: 386px;
        height: 398px;
      }
      .right {
        p {
          font-size: 40px;
          font-family: Arial-Black, Arial;
          font-weight: 900;
          color: #333333;
          line-height: 50px;
        }
        .right_img {
          display: flex;
          align-items: center;
          margin-top: -55px;
          img {
            width: 242px;
            height: 74px;
          }
          .fl {
            width: 300px;
            height: 241px;
            margin-left: 30px;
          }
        }
      }
    }
    .middle {
      background: url("../assets/image/Inicio/bkg_one.png");
      padding: 70px 0;
      box-sizing: border-box;
      h1 {
        text-align: center;
        margin-bottom: 40px;
      }
      .option {
        display: flex;
        justify-content: center;
        div {
          width: 254px;
          height: 250px;
          background: #fff;
          margin-right: 60px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: stretch;
          padding-top: 40px;
          box-sizing: border-box;
          &:last-child {
            margin-right: 0;
          }
          img {
            width: 86px;
            height: 86px;
            margin-bottom: 20px;
          }
          p {
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
