<template>
  <div class="Ayuda">
    <div class="content">
      <head-nav :activeNav="'Ayuda'"></head-nav>
      <div class="main">
        <h1>Los requisitos de solicitud</h1>
        <div class="collapse">
          <el-collapse>
            <el-collapse-item :name="1">
              <template slot="title">
                <transition name="slide-fade">
                  <p>Requisito indispensable:</p>
                </transition>
              </template>
              <div class="collapseCont">Edad: mayor de 18 años</div>
            </el-collapse-item>
            <el-collapse-item :name="2">
              <template slot="title">
                <p>Trámites indispensables:</p>
              </template>
              <div class="collapseCont">
                <p>1) Tener su DNI en la mano</p>
                <p>
                  2) Tener una cuenta de débito de CUALQUIER banco y que esté a
                  SU nombre
                </p>
                <p>3) Llenar su solicitud en la aplicación</p>
                <p>
                  NOTA: Recuerde que no pedimos ninguna clase de anticipos y que
                  la única forma de hacer su solicitud es por medio de la
                  aplicación. TODO EL PROCESO ES GRATUITO.
                </p>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>

        <h1>Solicitud de préstamo</h1>
        <div class="collapse">
          <el-collapse>
            <el-collapse-item :name="1">
              <template slot="title">
                <transition name="slide-fade">
                  <p>¿Cómo pido un préstamo?</p>
                </transition>
              </template>
              <div class="collapseCont">
                Entre a la App y regístrese con su número telefónico (le
                recomendamos crear una contraseña a la vez) y complete los datos
                básicos. Después de la autorización, de clic en el botón de
                “CONFIRMAR”, y el préstamo llegará a su cuenta bancaria.
              </div>
            </el-collapse-item>
            <el-collapse-item :name="2">
              <template slot="title">
                <p>¿Cómo puedo solicitar de nuevo un préstamo?</p>
              </template>
              <div class="collapseCont">
                Si le rechazaron su última solicitud, puede volver a solicitar
                después de 7 días; Una vez que se acredite su pago podrá
                solicitar un segundo préstamo ¡de inmediato! Y esta vez
                disfrutará de un monto mayor con un costo menor.
              </div>
            </el-collapse-item>
            <el-collapse-item :name="3">
              <template slot="title">
                <p>¿Cómo tener otro préstamo mientras tengo uno activo?</p>
              </template>
              <div class="collapseCont">
                Lamentablemente no puede pedir otro préstamo mientras tiene un
                activo. Debería liquidar su préstamo activo para poder solicitar
                otro. Eso sí, una vez que liquide, podrá solicitar otro de forma
                inmedia.
              </div>
            </el-collapse-item>
            <el-collapse-item :name="4">
              <template slot="title">
                <p>¿Cuánto tiempo tarda en depositar?</p>
              </template>
              <div class="collapseCont">
                Procesaremos su solicitud lo antes posible. Una vez aprobada tu
                solicitud, el depósito se completará en unos minutos. Sin
                embargo, debido a la diferencia en el horario laboral de los
                bancos peruanos, puede haber un retraso en su transferencia. No
                se preocupe, si tiene circunstancias especiales, puede
                contactarnos al +51904442997 de 9:00 a 19:00 en horario laboral.
              </div>
            </el-collapse-item>
            <el-collapse-item :name="5">
              <template slot="title">
                <p>¿Qué puedo hacer si falla el depósito?</p>
              </template>
              <div class="collapseCont">
                Estimado cliente, en este caso puede intentar comprobar si hay
                un error en el préstamo, o hay un problema con la recepción de
                su cuenta, y si el estado de su cuenta bancaria es normal. Si
                tiene circunstancias especiales, puede contactarnos al
                +51904442997 de 9:00 a 19:00 en horario laboral Le
                proporcionaremos la información más detallada y le brindaremos
                servicios más personalizados.
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>

        <h1>Forma de pago</h1>
        <div class="collapse">
          <el-collapse>
            <el-collapse-item :name="1">
              <template slot="title">
                <transition name="slide-fade">
                  <p>- ¿Cómo pago mi préstamo en línea?</p>
                </transition>
              </template>
              <div class="collapseCont">
                <p>1. Ingresar en CrediSol e iniciar sesión;</p>
                <p>
                  2. Dar clic en el botón "Banca por Internet" en la página de
                  pago;
                </p>
                <p>3. Rellenar el monto de pago y los datos personales;</p>
                <p>
                  4. Dar clic en "Confirmar", y entrar en la página de banco
                  automáticamente para pagar el préstamo.
                </p>
              </div>
            </el-collapse-item>
            <el-collapse-item :name="2">
              <template slot="title">
                <p>- ¿Cómo pago mi préstamo en efectivo?</p>
              </template>
              <div class="collapseCont">
                <p>1. Ingresar en CrediSol e iniciar sesión;</p>
                <p>2. Dar clic en el botón "Efectivo" en la página de pago;</p>
                <p>3. Acércate al punto de pago elegido;</p>
                <p>4. Indica que pagaras el servicio;</p>
                <p>5. Brinda tu código de pago y verifica el monto a pagar.</p>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>

        <h1>Solución de Problemas</h1>
        <div class="collapse">
          <el-collapse>
            <el-collapse-item :name="1">
              <template slot="title">
                <transition name="slide-fade">
                  <p>Me rechazaron mi préstamo, ¿por qué?</p>
                </transition>
              </template>
              <div class="collapseCont">
                Lamentamos que su solicitud haya sido rechazada; esto significa
                que no podrá utilizar nuestros servicios por el momento. ¡No se
                preocupe! En esta ocasión no fue candidato para un préstamo,
                pero esperamos que pronto se una a CrediSol. En cuanto sea
                elegible de nuevo, la App le mandará una notificación a su
                celular para realizar una nueva solicitud.
              </div>
            </el-collapse-item>
            <el-collapse-item :name="2">
              <template slot="title">
                <p>
                  Realicé el pago a tiempo (por adelantado), y me rechazaron mi
                  próximo préstamo, ¿por qué?
                </p>
              </template>
              <div class="collapseCont">
                Lamentamos que su solicitud haya sido rechazada. Hay varias
                razones por las que esto sucede. Para tener una mejor
                comprensión de lo sucedido, puede contactarnos al +51904442997
                de 9:00 a 19:00 en horario laboral o al servicio@credisol.pe,
                indicando nombre y teléfono registrado para que podamos
                brindarle un servicio personalizado.
              </div>
            </el-collapse-item>
            <el-collapse-item :name="3">
              <template slot="title">
                <p>
                  Realicé el pago, sin embargo, en la App no ha disminuido la
                  cantidad pendiente de pago, ¿qué hago?
                </p>
              </template>
              <div class="collapseCont">
                Lamentamos que se haya encontrado con este problema. Favor de
                tener más paciencia, porque los pagos en efectivo pueden tardar
                hasta 24 horas en verse reflejados. Y las transferencias
                interbancarias pueden tardar hasta 72 horas. Si pasado ese
                tiempo, no ve su pago reflejado, por favor contáctenos al
                teléfono +51904442997 en un horario de 9:00 a 19:00 o por correo
                servicio@credisol.pe con copia de su comprobante de pago y con
                gusto le ayudamos.
              </div>
            </el-collapse-item>
            <el-collapse-item :name="4">
              <template slot="title">
                <p>Accidentalmente pagué en exceso, ¿qué hago?</p>
              </template>
              <div class="collapseCont">
                No se preocupe, el dinero en exceso se depositará
                automáticamente en el saldo de su cuenta de CrediSol y se puede
                utilizar para el pago.
              </div>
            </el-collapse-item>
            <el-collapse-item :name="5">
              <template slot="title">
                <p>
                  Me quedo bloqueado al completar la información y no puedo
                  continuar con el siguiente paso, ¿qué hago?
                </p>
              </template>
              <div class="collapseCont">
                Lamentamos que se haya encontrado con este problema. Favor de
                verificar su red o vuelva a descargar la App para intentar
                completar su información.
              </div>
            </el-collapse-item>
            <el-collapse-item :name="6">
              <template slot="title">
                <p>La App me devolvió una interfaz de error, ¿qué hago?</p>
              </template>
              <div class="collapseCont">
                En este caso, le recomendamos que primero compruebe si la red
                funciona con normalidad o intente actualizar la App a la última
                versión. Si el problema persiste, puede contactarnos al
                +51904442997 de 9:00 a 19:00 en horario laboral o al correo
                servicio@credisol.pe y proporcione la siguiente información: su
                nombre completo, DNI de cliente, descripción de problema y la
                captura de pantalla que muestra la falla.
              </div>
            </el-collapse-item>
            <el-collapse-item :name="7">
              <template slot="title">
                <p>Quiero cambiar mi cuenta bancaria, ¿qué hago?</p>
              </template>
              <div class="collapseCont">
                Si no ha solicitado un préstamo, puede modificar la cuenta
                bancaria en la página “Yo-Información personal”; si ya ha
                enviado la solicitud, no necesita cambiar su tarjeta bancaria,
                puede usar cualquier tarjeta bancaria para realizar el pago;
                cuando liquide su pedido pendiente, también puede modificar la
                cuenta bancaria en la página “Yo-Información personal” antes de
                volver a solicitar un préstamo.
              </div>
            </el-collapse-item>
            <el-collapse-item :name="8">
              <template slot="title">
                <p>Quiero cambiar mi número de celular, ¿qué hago?</p>
              </template>
              <div class="collapseCont">
                Si desea cambiar su número de celular cuando está en el paso de
                registro, puede registrarse con su nuevo número de celular; si
                le queda un pedido por pagar, necesita liquidarlo primero, y
                luego proporcione a nuestro Servicio al Cliente sus números de
                celular antiguo y nuevo y su DNI; Si tiene un caso particular,
                por favor contáctenos al teléfono +51904442997 en un horario de
                9:00 a 19:00 o por correo servicio@credisol.pe.
              </div>
            </el-collapse-item>
            <el-collapse-item :name="9">
              <template slot="title">
                <p>¿Cómo puedo aumentar el monto de préstamo?</p>
              </template>
              <div class="collapseCont">
                Nuestro sistema evalúa el monto según su crédito. Por favor,
                mantenga un historial crediticio bueno y constante, lo que le
                ayudará a aumentar el monto de su préstamo. Sin embargo, estos
                datos se basan en el puntaje integral del sistema y no se
                permiten factores humanos. Esperamos su comprensión. Si tiene
                circunstancias especiales, puede contactarnos al +51904442997 de
                9:00 a 19:00 en horario laboral.
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
    <foot-page></foot-page>
  </div>
</template>

<script>
import headNav from "../components/headNav.vue";
import footPage from "../components/footPage.vue";

export default {
  name: "Ayuda",
  components: {
    headNav,
    footPage,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
};
</script>
<style lang='scss' scoped>
*::-webkit-scrollbar {
  display: none;
}
.Ayuda {
  width: 100%;
  height: 1000px;
  background: url("../assets/image/Inicio/bkg.png") no-repeat;
  background-size: 100% 100%;
  overflow-y: scroll;
  .content {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 123px;
    box-sizing: border-box;
    .main {
      h1 {
        font-size: 50px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        margin: 50px 0 30px;
      }
      .collapse {
        .el-collapse {
          border-bottom: none;
          .el-collapse-item {
            background: #ffffff;
            box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            margin-bottom: 30px;
            padding: 30px;
            box-sizing: border-box;
            p {
              font-size: 32px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
            }
            /deep/.el-collapse-item__wrap,
            /deep/.el-collapse-item__header {
              border-bottom: none;
            }
            /deep/.el-collapse-item__wrap {
              margin-top: 32px;
            }
            /deep/.el-icon-arrow-right {
              width: 38px;
              height: 28px;
              background: url("../assets/image/Ayuda/iconTop.png");
              background-size: 100% 100%;
              &:before {
                display: none;
              }
            }
            /deep/.el-collapse-item__arrow.is-active {
              transform: rotate(180deg);
            }
            .collapseCont,
            .collapseCont p {
              font-size: 28px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
          }
        }
      }
    }
  }
}
</style>