<template>
  <div class="Sobre">
    <head-nav :activeNav="'Sobre'"></head-nav>
    <div class="container">
      <h1>「 SOBRE NOSOTROS 」</h1>
      <p>
        Multicartera trabaja con diferentes plataformas locales de servicios de
        préstamos en Colombia, seleccionando de manera cuidadosa nuestros
        colaboradores. Para poder recomendar el préstamo adecuado para cada
        usuario, ayudándole a obtener un mayor préstamo con menores comisiones.
        Al mismo tiempo, cooperando con varias plataformas para ofrecer mayores
        prestaciones.
      </p>
    </div>
    <div class="main">
      <div class="main_margin">
        <div class="left">
          <h2>Contáctanos</h2>
          <div class="info">
            <div style="margin-right: 73px">
              <div class="left">
                <img src="../assets/image/Sobre/one.png" alt="" />
                <div>
                  <h5>Atención al Cliente:</h5>
                  <h6>+57 6015088314</h6>
                </div>
              </div>
              <div class="left">
                <img src="../assets/image/Sobre/four.png" alt="" />
                <div>
                  <h5>Horario de Atención:</h5>
                  <h6>LUN-VIER 9:00 A 19:00</h6>
                </div>
              </div>
            </div>
            <div>
              <div class="left">
                <img src="../assets/image/Sobre/three.png" alt="" />
                <div>
                  <h5>Email:</h5>
                  <h6>atc@multicartera.co</h6>
                </div>
              </div>
              <div class="left">
                <img src="../assets/image/Sobre/two.png" alt="" />
                <div>
                  <h5>WhatsApp:</h5>
                  <h6>962380942</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="../assets/image/Sobre/contactanos.png"
          alt=""
          class="one_img"
        />
      </div>
    </div>
    <com-yuan></com-yuan>
    <foot-page></foot-page>
  </div>
</template>

<script>
import headNav from "../components/headNav.vue";
import footPage from "../components/footPage.vue";
import comYuan from "../components/comYuan.vue";

export default {
  name: "Sobre",
  components: {
    headNav,
    footPage,
    comYuan,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
};
</script>
<style lang='scss' scoped>
.Sobre {
  width: 100%;
  position: relative;
  background: url("../assets/image/star.png") repeat;
  background-size: 100%;
  .container {
    width: 1200px;
    margin: 0 auto;
    z-index: 9999;
    position: relative;
    h1 {
      font-size: 52px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      margin-bottom: 40px;
      margin-top: 66px;
    }
    p {
      font-size: 32px;
      font-family: LucidaGrande-Bold, LucidaGrande;
      font-weight: bold;
      color: #333333;
      line-height: 45px;
      margin-bottom: 85px;
    }
  }
  .main {
    background: url("../assets/image/Sobre/bkg_one.png");
    .main_margin {
      width: 1200px;
      margin: 0 auto;
      padding-top: 50px;
      padding-bottom: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        h2 {
          font-size: 36px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #d90000;
          margin-bottom: 18px;
        }
        h3 {
          font-size: 28px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          margin-bottom: 62px;
        }
        .info {
          display: flex;
          font-family: MicrosoftYaHei;
          color: #333333;
          .left {
            display: flex;
            align-items: flex-start;
            margin-bottom: 50px;
            img {
              width: 86px;
            }
            div {
              margin-top: 15px;
              h5 {
                font-size: 22px;
              }
              h6 {
                font-size: 20px;
                margin-top: 7px;
              }
            }
          }
        }
      }
      .one_img {
        width: 423px;
        height: 435px;
      }
    }
  }
}
</style>