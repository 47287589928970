<template>
  <div class="yuan">
    <div class="yuan_right">
      <div class="one"></div>
      <div class="two"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss"  scoped>
.yuan {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: linear-gradient(
    135deg,
    rgba(220, 43, 66, 0.1) 0%,
    rgba(255, 255, 255, 0.1) 52%,
    rgba(220, 43, 66, 0.1) 100%
  );
  background-size: 100% 100%;
  img {
    width: 100%;
    height: 100%;
  }
  .yuan_right {
    position: absolute;
    right: 0;
    top: -243px;
    .one {
      width: 477px;
      height: 477px;
      border-radius: 50%;
      background: #a3f4af;
      opacity: 0.11;
    }
    .two {
      width: 307px;
      height: 342px;
      border-radius: 50%;
      background: #fef485;
      opacity: 0.12;
      position: absolute;
      top: 295px;
      right: 0;
    }
  }
}
</style>