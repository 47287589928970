<template>
  <div class="foot">
    <ul>
      <a href="/Inicio"><li>Inicio</li></a>
      <a href="/Sobre"><li>Sobre nosotros</li></a>
      <a href="/Aviso"><li>Aviso de privacidad</li></a>
      <a href="/Suger"><li>Quejas y Sugerencias</li></a>
      <a
        href="https://play.google.com/store/apps/details?id=bank.loan.fast.money.multicartera"
        target="_blank"
      >
        <img src="../assets/image/Inicio/playBtn.png" alt="" class="playBtn" />
      </a>
    </ul>
  </div>
</template>

<script>
export default {
  name: "footPage",
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.foot {
  width: 100%;
  height: 102px;
  background: #232323;
  display: flex;
  align-items: center;
  justify-content: center;
  // position: fixed;
  // bottom: 0;
  z-index: 9999;
  position: relative;
  ul {
    display: flex;
    align-items: center;
    a {
      li {
        list-style: none;
        color: #fff;
        margin-right: 80px;
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
      &:last-child li {
        margin-right: 0;
      }
    }
    .playBtn {
      width: 193px;
      height: 62px;
    }
  }
}
</style>
