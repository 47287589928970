<template>
  <div class="Aviso">
    <div class="content">
      <headNav :activeNav="'Aviso'"></headNav>
      <h1>「 POLÍTICA DE PRIVACIDAD 」</h1>
      <div class="privacidad">
        <p>
          Esta Política de privacidad (“Política”) se incorpora y está sujeta a
          los Términos y condiciones.
        </p>
        <p>
          POR FAVOR LEA ESTA POLÍTICA DE PRIVACIDAD CUIDADOSAMENTE. ESTA
          POLÍTICA DESCRIBE LA MANERA EN QUE LA COMPAÑÍA O SUS PROVEEDORES
          (“CrediCol”) RECOPILAN, ALMACENAN, PROTEGEN Y UTILIZAN SU INFORMACIÓN
          PERSONAL. USTED ACEPTA ESTA POLÍTICA DE PRIVACIDAD AL VISITAR NUESTRA
          PLATAFORMA O UTILIZAR CUALQUIERA DE NUESTROS SERVICIOS (“SERVICIO”) A
          TRAVÉS DE LA PLATAFORMA. SI NO ACEPTA ESTA POLÍTICA DE PRIVACIDAD, NO
          PODRÁ UTILIZAR LOS SERVICIOS.
        </p>
        <p>
          Desde el 23-08-2023, Multicartera cambio oficialmente su nombre a
          CrediCol, el cambio de nombre solo implica branding y visual, no
          afecta el uso normal de todas las funcionalidades o servicios, los
          usuarios originales de Multicartera pueden seguir utilizandolo con
          normalidad.Toda la información y recopilación, transmisión y
          almacenamiento de datos del usuario permanecen inalterados, y
          cumpliremos con la política de privacidad para garantizar la seguridad
          de la información del usuario.
        </p>
        <p class="title">I. Compromiso solemne</p>
        <p>
          En todo caso, esas entidades estarán igualmente sujetas a las
          obligaciones de confidencialidad en el manejo de la información con
          las limitaciones legales impuestas por las leyes aplicables sobre la
          materia, en la jurisdicción donde ésta se recolecte, archive, procese
          o transmita Contactos.
        </p>
        <p class="title">II.Datos Personales sujetos a Tratamiento</p>
        <p class="title">Número de teléfono</p>
        <p>
          Usaremos el código (IMEl) de su celular, ya que con él podemos
          asegurar que otros celulares no puedan reemplazarle al solicitar un
          préstamo, previniendo fraudes. No obtenemos automáticamente el número
          de teléfono del usuario para completar la página de inicio de sesión
          de la aplicación, ni tampoco se almacena datos de solicitantes a los
          que no se les apruebe el préstamo. Cifrada subirlo a nuestro servidor
          y nuestro proveedor externo
          <span style="color: #0000ff">https://api.multicartera.co</span>
          y <span style="color: #0000ff">https://bud.multicartera.co/</span>,
          para que analice su riesgo frente a préstamos múltiples y evalue si se
          puede aprobar préstamo o prevenir fraude.
        </p>
        <!-- <p class="title">Grabación de llamadas</p>
        <p>
          Se nos permite ver el historial de llamadas, le proporcionaremos una
          llamada de voz para la evaluación del riesgo crediticio, confirmaremos
          que nuestra aplicación está instalada en su teléfono móvil y solo
          descargaremos y transmitiremos el contenido del historial de llamadas
          de manera segura. La información sera subida a nuestro servidor de
          manera cifrada y a nuestro proveedor externo
          <span style="color: #0000ff">https://api.multicartera.co</span>
          y <span style="color: #0000ff">https://bud.multicartera.co/</span>,
          para que analice su riesgo frente a préstamos múltiples y evalue si se
          puede aprobar préstamo o prevenir fraude.
        </p> -->
        <p class="title">Cámara</p>
        <p>
          Permite que obtenga permisos de cámara para capturar imágenes/videos y
          acelerar el trámite de solicitud de préstamo.
        </p>
        <p class="title">Almacenamiento</p>
        <p>
          Permite cargar documentos e imágenes para la solicitud de préstamo.
        </p>
        <p class="title">Información básica del móvil</p>
        <p>
          Obtendremos la información，como tipo de celular para ofrecer mejor
          servicio.
        </p>
        <p class="title">Lista de App instalada</p>
        <p>
          Recopilamos información de metadatos para cada aplicación instalada,
          incluido el nombre de la aplicación, el nombre del paquete, el tiempo
          de instalación, el tiempo de actualización, el nombre de la versión y
          el código de la versión de cada aplicación instalada en el dispositivo
          para evaluar su crédito y cifrada subirlo a nuestro servidor y nuestro
          proveedor externo
          <span style="color: #0000ff">https://api.multicartera.co</span>
          y <span style="color: #0000ff">https://bud.multicartera.co/</span>,
          aunque la app esté cerrada o no esté en uso lo hará, a fin de
          identificar y analizar su comportamiento y riesgo frente a préstamos
          múltiples para evaluar si se puede procesar el préstamo o no ayudando
          a prevenir fraude.
        </p>
        <p class="title">SMS</p>
        <p>
          Nuestra aplicación cargará y transferirá sus datos, incluidos
          contactos, números de teléfono, mensajes SMS, desde su teléfono móvil
          a nuestro servidor
          <span style="color: #0000ff">https://api.multicartera.co</span>
          y <span style="color: #0000ff">https://bud.multicartera.co/</span>.
          Las informaciones cargadas y transmitidas se utilizarán para la
          gestión de riesgos y controles antifraude. Nunca compartiremos datos
          con terceros sin su permiso. Solo recopilaremos su información con su
          consentimiento. Estos mensajes SMS ayudan a determinar si usted es
          elegible para nuestros servicios y optimizar nuestro modelo de
          crédito. Sus datos se mantendrán en la plataforma durante 1 año a
          partir de la fecha de carga. Si desea eliminar sus datos privados,
          puede enviar una solicitud al servicio de atención al cliente a este
          correo electrónico:
          <span style="color: #0000ff">{{ dataInfo.email }}</span
          >, y podemos eliminarlos manualmente dentro de (5 días hábiles).
        </p>
        <p class="title">Modelo y estado del dispositivo</p>
        <p>
          Nuestra aplicación recopila y monitorea información específica sobre
          su dispositivo, incluido su modelo de hardware, modelo de compilación,
          RAM, almacenamiento, identificadores únicos de dispositivo como IMEI,
          número de serie, SSAID,AAID, información SIM. Cargaremos la
          información de su dispositivo en nuestro servidor para brindarle más
          conveniencia financiera.
        </p>
        <p class="title">Ubicación</p>
        <p>
          Recopilamos y detectamos la información de ubicación de su dispositivo
          para brindarle servicios para su solicitud de préstamo, reducir los
          riesgos asociados con su solicitud de préstamo y brindar ofertas de
          préstamos personalizadas preaprobadas. Esto también nos ayuda
          verificar su dirección, tomar mejores decisiones sobre el riesgo
          crediticio y acelerar el proceso de su préstamo.
        </p>
        <p class="title">Cámara</p>
        <p>
          Necesitamos usar una cámara para que pueda completar automáticamente
          los detalles relevantes o iniciar una llamada de audio / video para
          hacer un préstamo, de modo que pueda escanear o capturar fácilmente
          los comprobantes de préstamorequeridos y ahorrar tiempo. Esto segura
          que obtenga una experiencia perfecta al usar la aplicación.
        </p>
        <p class="title">Calendario</p>
        <p>
          El acceso al calendario está habilitado al analizar su elegibilidad
          para un préstamo, recordándole las fechas específicas de los pagos que
          debe evitar.Almacenamiento de datos del calendario: los datos se
          cifrarán y cargarán en nuestros servidores de membresía
          <span style="color: #0000ff">https://api.multicartera.co</span>
          y <span style="color: #0000ff">https://bud.multicartera.co/</span> a
          través del protocolo HTTPS. Nunca compartiremos estos datos con
          terceros,Se usa para recordarle la fecha de pago para evitar el
          impacto del pago vencido en su crédito.
        </p>
        <p class="title">Actividad en apps</p>
        <p>
          Necesitamos obtener la actividad en apps, incluido nombre del paquete,
          nombre de la aplicación, la hora de la primera instalación y la hora
          de actualización de la aplicación, para evaluar si el cliente es
          elegible para solicitar un préstamo. Cargaremos esta información a
          nuestro servidor
          <span style="color: #0000ff">https://api.multicartera.co</span>
          y
          <span style="color: #0000ff">https://bud.multicartera.co/</span>
          encriptado, y se usa para identificar y analizar comportamiento y
          riesgo en múltiples préstamos para evaluar si un préstamo puede
          procesarse, lo que ayuda a prevenir el fraude.
        </p>
        <p class="title">Almacenamiento (solo fotos en galería)</p>
        <p>
          Cuando usuarios seleccionan fotos del álbum de su teléfono móvil, la
          aplicación necesita acceso a la galería dentro de los archivos de
          multimedia del dispositivo. Este acceso facilita la selección y carga
          de imagenes requeridos en la solicitud de préstamo. Solamente los
          fotos seleccionados manualmente por los usuarios se transmitirán a
          nuestra aplicación de manera encriptada, y se guardarán por 1 año a
          partir de la fecha de carga. Nunca recopilaremos su foto sin su
          consentimiento, si desea eliminar los datos de fotos, puede enviar una
          solicitud al servicio de atención al cliente a este correo
          electrónico: <span style="color: #0000ff">{{ dataInfo.email }}</span
          >, podemos eliminar los datos manualmente en 5 días hábiles.
        </p>
        <p class="title">Información personal del usuario</p>
        <p>
          Para realizar control de riesgo y evaluar la elegibilidad del usuario,
          necesitamos usar las informaciones personales incluido nombre
          completo, estado civil, género, fecha de nacimiento, edad, país,
          identificación oficial vigente, clave única de registro de población ,
          correo electrónico, datos académicos, dirección residencial.
          Realizamos depósito bancario directamente mediente el número de cuenta
          y/o tarjeta y Clave Bancaria Estandarizada proporcionados por los
          usuarios.
        </p>
        <p class="title">
          III. Utilizaremos los servicios necesarios que se soliciten, como los
          siguientes:
        </p>
        <p>
          •Cumplir la relación contractual derivado de la solicitud y/o
          contratación de los servicios.
        </p>
        <p>
          •Administrar, operar y dar seguimiento a los servicios que solicita o
          contrata.
        </p>
        <p>
          •Realizar el análisis de la información proporcionada por el Titular,
          a fin de determinar conforme a las disposiciones legales vigentes el
          nivel de riesgo, viabilidad de pago, perfil transaccional, y cualquier
          evaluación que sea requerida por la normativa para el otorgamiento de
          los servicios.
        </p>
        <p>•Formalizar la relación contractual entre CrediCol y el Titular.</p>
        <p>
          •Incluir en nuestra base de datos, integrar expedientes, de manera
          directa o a través de un tercero.
        </p>
        <p>
          •Procesar las solicitudes que realice por medio de nuestros Medios
          Electrónicos.
        </p>
        <p>•Crear su perfil de usuario en la App.</p>
        <p>
          •Consultar y realizar investigaciones crediticias antes de sociedades
          de información crediticia en período apropiado.
        </p>
        <p>•Ofrecer una ampliación o mejora de los servicios contratados.</p>
        <p>•Informar sobre actualizaciones de servicios.</p>
        <p>
          •Para cumplir las obligaciones de préstamo rápido contenidas de las
          leyes aplicables en Colombia.
        </p>
        <p>
          •Enviar todo tipo de notificaciones a través de cualquier medio para
          requerir el cumplimiento de sus obligaciones derivadas de la solicitud
          o el Contrato celebrado entre préstamo rápido y el Titular.
        </p>
        <p>
          •Llevar a cabo inteligencia comercial, realizar investigaciones de
          mercado que podrían dar visibilidad, evaluar y mejorar nuestros Medios
          Electrónicos.
        </p>
        <p>
          De manera adicional, utilizaremos su información personal para las
          siguientes finalidades secundarias que no son necesarias para el
          servicio solicitado. Pero nos permiten poder facilitan y brindar una
          mejor atención:
        </p>
        <p>
          •Envío de publicidad, telemarketing, mercadotecnia, ofertas,
          promociones, boletín de noticias y ofertas o eventos especiales que
          puedan ser de interés del Titular, así como servicios o productos
          ofrecidos por socios comerciales.
        </p>
        <p>•Mercadotecnia o publicitaria.</p>
        <p>•Prospección comercial.</p>
        <p class="title">
          IV.Para indicar su negativa respecto al tratamiento de sus datos
          personales.
        </p>
        <p>
          En caso de que desees revocar tu consentimiento o negarte al
          tratamiento de tus datos personales para las Finalidades Secundarias,
          con posterioridad a la firma y aceptación del presente Aviso, deberás
          enviar un correo electrónico a la dirección indicada en la sección
          anterior al Departamento de Protección de Datos Personales/Equipo de
          Atención al Cliente,
          <span style="color: #0000ff">{{ dataInfo.email }}</span> , siguiendo
          el procedimiento para la Negativa al Tratamiento y Revocación del
          Consentimiento que se describe en este Aviso.
        </p>
        <p class="title">V.Transferencia de Datos.</p>
        <p>
          El titular acepta que préstamo rápido compartirá sus Datos Personales
          con ellos: (i) sus sociedades controladoras, subsidiarias o afiliadas
          bajo el control común de préstamo rápido, así como a nuestra sociedad
          matriz o a cualquier sociedad de nuestro grupo corporativo que opere
          bajo los mismos procesos y políticas internas, (ii) con autoridades
          competentes, en caso de ser solicitados, en términos de la legislación
          aplicable, (iii) con la(s) sociedad(es) de información crediticia con
          la(s) cual(es) préstamo rápido haya contratado (iv) sociedades o
          compañías encargadas de cobranza y recolección de pagos (v) con otras
          empresas en caso de ser adquiridos, fusionados o cualquier otra
          operación similar por esa compañía, (vi) con otras sociedades que
          provean infraestructura y/o servicios para la prestación de servicios
          de préstamo rápido (encargados) (vii) terceros incluidos entidades
          financieras, para cesión o descuento de cartera de crédito, endoso o
          cualquier otra forma de negociación de la cartera crediticia, (viii)
          socios comerciales con quien se tengan celebrados contratos para la
          comercialización de productos y/o servicios en beneficio del Titular,
          y (ix) en cualquier otro de los supuestos establecidos en la Ley.
        </p>
        <p>
          CrediCol comunicará a los receptores, la presente Política de
          Privacidad, con el fin de asegurar que su información se utilice en
          los términos de esta.
        </p>
        <p class="title">VI. Renovación del Consentimiento</p>
        <p>
          Puede revocar cualquier consentimiento que le hayamos dado para el
          tratamiento de sus datos personales. No obstante, debe tener en cuenta
          que no siempre podremos atender su solicitud o terminar el uso de
          forma inmediata, ya que es posible que la ley nos obligue a seguir
          tratando sus datos personales. Asimismo, debe tener en cuenta que,
          para algunos fines, la retirada de su consentimiento supondrá que
          dejemos de prestarle los servicios solicitados o que finalicemos su
          relación con nosotros.
        </p>
        <p>
          Para revocar su consentimiento deberá presentar su solicitud a través
          del siguiente medio:
        </p>
        <p>
          A través del correo electrónico
          <span style="color: #0000ff">{{ dataInfo.email }}</span
          >, en un horario de 8:00 a 17:00 horas de lunes a viernes.
        </p>
        <p>
          Con relación al procedimiento y requisitos para la revocación de su
          consentimiento, le informamos lo siguiente:
        </p>
        <p>
          a)¿A través de qué medios pueden acreditar su identidad el titular y,
          en su caso, su representante, así como la personalidad de este último?
        </p>
        <p>
          A través de una copia de su Identificación Oficial (credencial para
          votar expedida por el INE), o el documento a través del cual acredite
          su representación legal en caso de que no sea el Titular.
        </p>
        <p>de que no sea el Titular</p>
        <p>
          •Nombre y domicilio u otro medio para comunicarle la respuesta a su
          solicitud, así como los documentos que acrediten su identidad o en su
          caso, la representación legal.
        </p>
        <p>
          •Cualquier otro elemento o documento que facilite la localización de
          los Datos Personales.
        </p>
        <p>
          •La solicitud deberá ser firmada al final del escrito y rubricada al
          calce de cada una de las hojas.
        </p>
        <p>c)¿En cuántos días le daremos respuesta a su solicitud?</p>
        <p>20 (veinte) días hábiles.</p>
        <p>d)¿Qué medio le comunicaremos la respuesta a su solicitud?</p>
        <p>
          Correo electrónico proporcionado por el Titular a través de la App
          móvil
        </p>
        <p>
          e)Para más información sobre el procedimiento, ponemos a disposición
          los siguientes medios:
        </p>
        <p>
          Correo electrónico
          <span style="color: #0000ff">{{ dataInfo.email }}</span> , en un
          horario de 8:00 a 17:00 horas de lunes a viernes.
        </p>
        <p class="title">
          VII.¿Cómo puede conocer los cambios en esta Política de Privacidad?
        </p>
        <p>
          La presente Política de Privacidad puede tener ciertas modificaciones,
          cambios o actualizaciones derivadas de nuevos requerimientos legales;
          de nuestras propias necesidades por los productos o servicios que
          ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro
          modelo de negocio, o por otras causas.
        </p>
        <p>
          Nos comprometemos a mantenerlo informado sobre los cambios que pueda
          sufrir la presente Política de Privacidad, a través de: Página web y
          App móvil.
        </p>
        <p>
          El procedimiento a través del cual se llevarán a cabo las
          notificaciones sobre cambios o actualizaciones a la presente Política
          de Privacidad es la siguiente:
        </p>
        <p>
          Los cambios a la Política de Privacidad se darán a conocer a los
          Titulares por medio de la página web y la App móvil, el titular podrá
          expresar su negativa respecto de los cambios enviando un correo
          electrónico al préstamo rápido, siempre y cuando las solicitudes sean
          viables, en caso contrario se le dará a conocer dicha situación y
          también los impedimentos legales. En caso de que los cambios requieran
          el consentimiento expreso del Titular, se le solicitará a través de la
          App móvil.
        </p>
        <p class="title">Ⅷ.Retención y eliminación de datos</p>
        <p>
          CrediCol se compromete a proteger sus datos personales del acceso no
          autorizado, el uso indebido y la divulgación utilizando las medidas de
          seguridad adecuadas según el tipo de datos y cómo los procesamos.
        </p>
        <p>
          Conservamos información sobre usted para brindarle una experiencia
          fluida, para comunicarnos con usted en caso de que se requiera soporte
          y sobre su cuenta, para detectar, mitigar, prevenir e investigar
          actividades fraudulentas o ilegales. Conservamos sus datos durante el
          tiempo que sea necesario para prestarle nuestros servicios. También
          podemos retener y usar su información personal según sea necesario
          para cumplir con nuestras obligaciones legales, resolver disputas y
          hacer cumplir nuestros acuerdos. Sujeto a esta sección, podemos
          eliminar sus datos previa solicitud razonable por escrito para la
          misma. Sin embargo, es posible que no pueda utilizar nuestros
          servicios después de la eliminación.
        </p>
        <p class="title">Ⅸ.Tus derechos</p>
        <p>
          Usaremos sus datos con el fin de compilar estadísticas relacionadas
          con nuestra base de usuarios o cartera de préstamos y podemos divulgar
          dicha información a terceros para tales fines, siempre que dicha
          información sea siempre anónima. Si deseamos utilizar su información
          con fines de marketing, le informaremos antes de dicho uso. Tendrá
          derecho a evitar dicho uso informándonos, dentro de los 10 días
          posteriores a la notificación del uso propuesto, que no desea divulgar
          dicha información. También puede ejercer el derecho en cualquier
          momento poniéndose en contacto con nosotros en
          <span style="color: #0000ff">{{ dataInfo.email }}</span>
        </p>
        <p class="title">X. Términos y Condiciones</p>
        <p>Por favor visite este enlace para más detalles:</p>
        <p class="title">XI.Contáctenos</p>
        <p>
          Si tiene alguna inquietud con respecto a CrediCol o nuestros
          servicios, comuníquese con nosotros:
        </p>
        <p>
          Identificación de correo electrónico:
          <span style="color: #0000ff">{{ dataInfo.email }}</span>
        </p>
      </div>
    </div>
    <com-yuan></com-yuan>
    <foot-page></foot-page>
  </div>
</template>

<script>
import headNav from "../components/headNav.vue";
import footPage from "../components/footPage.vue";
import comYuan from "../components/comYuan.vue";
import infoList from "@/mixins/infoList.js";

export default {
  name: "Aviso",
  mixins: [infoList],
  components: {
    headNav,
    footPage,
    comYuan,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
// *::-webkit-scrollbar {
//   display: none;
// }
.Aviso {
  width: 100%;
  position: relative;
  background: url("../assets/image/star.png") repeat;
  background-size: 100%;
  .content {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 50px;
    box-sizing: border-box;
    z-index: 9999;
    position: relative;
    h1 {
      font-size: 52px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      margin: 66px 0 40px 0;
    }
    .privacidad {
      p {
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-bottom: 20px;
      }
      .title {
        font-weight: 600;
        font-size: 28px;
      }
    }
  }
}
</style>
