<template>
  <div class="Suger">
    <div class="content">
      <headNav :activeNav="'Suger'"></headNav>
      <div class="wrapper">
        <h1>「 QUEJAS Y SUGERENCIAS 」</h1>
        <div class="info">
          <p>
            Por favor llene el formulario o envíenos un correo y nos pondremos
            en contacto con usted a la brevedad.
          </p>
          <p><span>Correo electrónico: </span>atc@multicartera.co</p>
          <p><span>Título de correo: </span>Número telefónico + Su problema</p>
          <p><span>Contenido de correo: </span></p>
          <p>1. ¿Tiene algún problema?</p>
          <p>2. Método de contacto o número de teléfono móvil registrado</p>
        </div>
      </div>
    </div>
    <com-yuan></com-yuan>
    <foot-page></foot-page>
  </div>
</template>

<script>
import headNav from "../components/headNav.vue";
import footPage from "../components/footPage.vue";
import comYuan from "../components/comYuan.vue";

export default {
  name: "Suger",
  components: { headNav, footPage, comYuan },
  data() {
    return {};
  },
};
</script>
<style scoped>
</style>
<style  lang='scss' scoped>
.Suger {
  position: relative;
  height: 100%;
  background: url("../assets/image/star.png") repeat;
  background-size: 100%;
  .wrapper {
    width: 1200px;
    margin: 0 auto;
    z-index: 9999;
    position: relative;
    margin-bottom: 253px;
    h1 {
      margin-top: 66px;
      font-size: 52px;
    }
    .info {
      width: 100%;
      height: 464px;
      background: #ffffff;
      border-radius: 8px;
      border: 2px solid #cccccc;
      margin-top: 40px;
      padding: 60px 40px;
      box-sizing: border-box;
      p {
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        margin-bottom: 20px;
        span {
          color: #c90000;
        }
      }
    }
  }
}
</style>