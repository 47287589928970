<template>
	<div class="headNav">
		<img @click="getActive('Inicio')" class="logo" src="@/assets/image/Inicio/logo.png" alt="" />
		<ul class="nav">
			<li :class="activeNav == 'Inicio' ? 'active' : ''">
				<a @click="getActive('Inicio')">Inicio</a>
			</li>
			<li :class="activeNav == 'Sobre' ? 'active' : ''">
				<a @click="getActive('Sobre')">Sobre nosotros</a>
			</li>
			<li :class="activeNav == 'Aviso' ? 'active' : ''">
				<a @click="getActive('Aviso')">Aviso de privacidad</a>
			</li>
			<li :class="activeNav == 'Suger' ? 'active' : ''">
				<a @click="getActive('Suger')">Quejas y Sugerencias</a>
			</li>
			<li>
				<a @click="goServicio()">TÉRMINOS Y CONDICIONES</a>
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		name: 'headNav',
		props: ['activeNav'],
		data() {
			return {
				// active: "Inicio",
				index: null,
			}
		},
		created() {
			console.log(this.activeNav)
			// this.active = this.activeNav;
			// console.log(nav, this.active);
			// if (nav) {
			//   this.active = nav;
			// } else {
			//   localStorage.setItem('activeNav', "Inicio");
			// }
		},
		methods: {
			goServicio() {
				window.open('https://www.multicartera.co/h5/servicio.html')
			},
			getActive(val) {
				console.log(111)
				let url = ''
				switch (val) {
					case 'Inicio':
						url = '/Inicio'
						break
					case 'Sobre':
						url = '/Sobre'
						break
					case 'Ayuda':
						url = '/Ayuda'
						break
					case 'Aviso':
						url = '/Aviso'
						break
					case 'Suger':
						url = '/Suger'
						break
				}
				this.$router.push(url)
			},
			handleApp() {
				this.index = 1
				this.activeNav = ''
				window.open('https://www.ekecash.pe/home')
			},
		},
	}
</script>

<style lang="scss" scoped>
	.headNav {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 1200px;
		margin: 0 auto;
		position: relative;
		z-index: 9999;
		top: 0;
		img {
			width: 205px;
		}
		.nav {
			display: flex;
			align-items: center;
			li {
				list-style: none;
				margin-right: 50px;
				font-size: 16px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #333333;
				&:last-child {
					margin-right: 0;
				}
			}

			.active {
				color: #c90000;
				background: #ffffff;
				box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08), 0px 4px 6px 0px rgba(50, 50, 93, 0.11);
				border-radius: 4px;
				padding: 9px 20px;
				box-sizing: border-box;
			}
		}
	}
</style>
